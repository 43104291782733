import {
    Button,
    Grid,
    Typography,
    makeStyles,
    Divider,
    Modal,
    Paper,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import * as ALLMCORE from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import {
    Col,
    Div,
    H6,
    Image,
    Row,
    TextArea,
    TextInput,
} from "qdm-component-library";
import React, { useContext, useEffect, useState } from "react";
import { CommonTabList, CommonTable, GenerateForm, SelectBox } from "../../components";
import { AlertContext } from "../../contexts";
import {
    constructRefTypeEditDataPayload,
} from "../../screens/availabilityManagement/timeTableManagement/TimeTableManagementForm/constructJson";

import { RichTextfield } from "..";
// Styles
import qdmstyles from "./styles.json";
import { AlertProps, AxiosCall, extractIdAndValue } from "../../utils";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Ajv from "ajv";
import {RichText} from "./richText";
import axios from "axios"
import Config from "../../config";

const styles = {
    labelStyle: {
        color: "#6F6F6F",
        fontSize: "13px",
        marginBottom: "6px",
        fontFamily: `pc_regular !important`,
    },
    borderStyle: {
        borderRadius: 8,
        borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
        borderRadius: 8,
        border: "1px solid red",
    },
};
const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
    },
    addBtn: {
        float: "right",
        fontSize: "14px",
        fontWeight: 600,
    },
    title: {
        fontFamily: "poppinsemibold",
        display: "inline",
        fontSize: "12px",
    },
    form: {
        paddingTop: 15,
    },
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalPaper: {
        width: "90vw",       
        backgroundColor: theme.palette.background.paper,
    },
    modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        padding: 16,
        "& > .MuiTypography-root": {
            fontSize: 14,
            color: "rgba(0, 28, 60, 1)",
            fontWeight: 600,
            textTransform: "uppercase",
        },
        "& > .MuiIconButton-root": {
            background: "rgba(1, 32, 92, 0.1)",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1.1rem",
        },
    },
    modalHeaderDivider: {
        background: "#DEE5EC",
    },
    modalBodyContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        padding: "20px 16px",
    },
    modalInputContainer: {
        display: "flex",
        flexDirection: "column",
        // gap: 8,
        "& > .MuiTypography-root": {
            color: "rgb(106, 120, 136)",
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0px",
            textAlign: "left",
            textTransform: "capitalize",
            margin: "0px",
        },
    },
    modalInput: {
        width: "345px",
    },
    modalActionContainer: {
        display: "flex",
        gap: 8,
        marginLeft: "auto",
    },
    modalSaveAndAdd: {
        color: "#0071F2",
        backgroundColor: "#F1F6FC",
        borderRadius: "8px",
    },
    modalAdd: {
        backgroundColor: "#01205C",
        borderRadius: "8px",
    },
    instructionValueContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
    },
}));

const NotificationEditView = (props) => {
    const alert = useContext(AlertContext);
    const classes = useStyles();

    const [notificationTypes, setNotificationTypes] = useState([]);
    const [alertSchemaList, setalertSchemaList] = useState([]);
    const [openSchema, setOpenSchema] = useState({
        isAlert:false,
        isBody:false
    });
    const [warningAlert, setwarningAlert] = useState();  
    const [alertSchema, setalertschema] = useState();
    const [body, setBody] = useState();
    const [isNew,setisNew] = useState(true);

    const [schemaVal,setSchemaVal] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [state, setState] = React.useState({
        _id: "",
        notificationtype: [],
        alertcode: "",
        alertname: "",
        refType: "",
        refId: "",
        alertschema: "",
    });
    const [error, setError] = useState({
        iserror:false,
        isListError:false,
        isFieldError:{}
    });

    const [notificationTypeData, setNotificationTypeData] = useState({
        index:null,
        notificationType: "",
        from: "",
        to: "",
        cc: "",
        bcc: "",
        subject: "",
        body: "",
        attachment: "",
        retryCount: "0",
        failureInitTo: "",
    });

    const getNotificationTypesDD = async () => {
        let data = JSON.stringify({
            db_name: process.env.REACT_APP_METADATA_DB_NAME,
            filter: {
                type: ["ALERTNOTIFICATIONTYPE"],
                tenantid: process.env.REACT_APP_TENANTID,
                facilityid: process.env.REACT_APP_FACILITY_ID,
                lang: "en",
            },
            queryid: process.env.REACT_APP_QUERY_GENERAL_MASTER,
        });
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_READ_QDM_QUERY,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios
            .request(config)
            .then((response) => {
                setNotificationTypes(response?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAlertSchema = async () => {
        let data = JSON.stringify({
            db_name: process.env.REACT_APP_DATABASE_NAME,
            filter: {
                tenantid: process.env.REACT_APP_TENANTID,
                facilityid: process.env.REACT_APP_FACILITY_ID,
                factid: "",
            },
            queryid: Config.getAlertSchemaQueryId,
        });
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_READ_QDM_QUERY,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios
            .request(config)
            .then((response) => {
                let respArr = []
                let FactArr = []
                response?.data?.map((v,i)=>{
                    if(props?.isEdit){
                        v?.facts?.map((val,i)=>{
                            if(v?._id === props?.alertNotific?.alertschema?._id){
                                let factObj = {
                                    label: val?.displayname,
                                    value: val?.fieldname,
                                }
                                FactArr.push(factObj);
                            }
                        })
                    }
                    let obj = {
                        _id: v?._id,
                        factid: v?.factid,
                        factname: v?.factname,
                        label: v?.factname,
                        facts: v?.facts
                    }
                    respArr.push(obj)
                })
                setSchemaVal(FactArr);
                setalertSchemaList(respArr);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (type,val) => {
        if(type === "alertschema"){
            if(state?.notificationtype?.length > 0){
                setwarningAlert(true);
            }else{
                let factsArr = []
                setNotificationTypeData({
                    index:null,
                    notificationType: "",
                    from: "",
                    to: "",
                    cc: "",
                    bcc: "",
                    subject: "",
                    body: "",
                    attachment: "",
                    retryCount: "0",
                    failureInitTo: "",
                });
                val?.facts?.map((v,i)=>{
                    let factObj = {
                        label: v?.displayname,
                        value: v?.fieldname,
                    }
                    factsArr.push(factObj);
                })
                setSchemaVal(factsArr);
                setState({
                    ...state,
                    [type]: val
                })     
            }
            return;
        }
        setState({
            ...state,
            [type]: val
        })
    }
    const checkHashTextAndEmail = (val) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let resp = false;
        if(Array.isArray(val)){
            val?.every((value) => {
                value = value.trim();
                let checkHash = (value?.charAt(0)==="#" && value?.charAt(value?.length -1)!=="#") ||
                (value?.charAt(0)!=="#" && value?.charAt(value?.length -1)==="#")
                if (value === "") return false; 
                    if (checkHash) {
                        resp = true; 
                        return false;
                    } else if (!checkHash && value?.includes("##")) {
                        resp = true; 
                        return false;
                    } else if (value?.charAt(0)!=="#" && value?.charAt(value?.length -1)!=="#" &&!emailRegex.test(value)) {
                        resp = true; 
                        return false;
                    } else {
                        resp = false;
                        return true;
                    }
            });
        }
        return resp;
    }
    const checkHash = (val,type) => {
        let resp = false;
        if(Array.isArray(val)){
            val?.every((value) => {
                if (type==="attach"){
                    value = value.trim();
                    let checkHash = (value?.charAt(0)==="#" && value?.charAt(value?.length -1)!=="#") ||
                    (value?.charAt(0)!=="#" && value?.charAt(value?.length -1)==="#")
                    if (value === "") return false; 
                    if (checkHash) {
                        resp = true; 
                        return false;
                    } else if (!checkHash && value?.includes("##")) {
                        resp = true; 
                        return false;
                    } else {
                        resp = false;
                        return true;
                    }
                } else {
                    resp = false;
                    return true;
                }
            });
        }
        return resp;
    }
    const addNotificationTypes = () => {

        if(notificationTypeData?.notificationType?.label &&
            (notificationTypeData?.notificationType?.label !== "email" ? notificationTypeData?.from : true) &&
            notificationTypeData?.to &&
            notificationTypeData?.subject &&
            notificationTypeData?.body &&
            notificationTypeData?.retryCount
        ){
            const From = notificationTypeData?.from ? notificationTypeData?.from?.endsWith(",") ? notificationTypeData?.from?.replace(/,$/, "").split(',').filter(value => value.trim() !== "") 
            : notificationTypeData?.from?.split(',').filter(value => value.trim() !== "") : "";
            const To = notificationTypeData?.to?.endsWith(",") ?  notificationTypeData?.to?.replace(/,$/, "").split(',').filter(value => value.trim() !== "") 
            : notificationTypeData?.to?.split(',').filter(value => value.trim() !== "");
            const CC = notificationTypeData?.cc?.endsWith(",") ? notificationTypeData?.cc?.replace(/,$/, "").split(',').filter(value => value.trim() !== "")
            : notificationTypeData?.cc?.split(',').filter(value => value.trim() !== "");
            const BCC = notificationTypeData?.bcc?.endsWith(",") ? notificationTypeData?.bcc?.replace(/,$/, "").split(',').filter(value => value.trim() !== "")
            : notificationTypeData?.bcc?.split(',').filter(value => value.trim() !== "");
            const FailInit = notificationTypeData?.failureInitTo?.endsWith(",") ? notificationTypeData?.failureInitTo?.replace(/,$/, "").split(',').filter(value => value.trim() !== "")
            : notificationTypeData?.failureInitTo?.split(',').filter(value => value.trim() !== "");
            const Sub = notificationTypeData?.subject?.endsWith(",") ? notificationTypeData?.subject?.replace(/,$/, "").split(',').filter(value => value.trim() !== "")
            : notificationTypeData?.subject?.split(',').filter(value => value.trim() !== "");
            const Attach = notificationTypeData?.attachment?.endsWith(",") ? notificationTypeData?.attachment?.replace(/,$/, "").split(',').filter(value => value.trim() !== "")
            : notificationTypeData?.attachment?.split(',').filter(value => value.trim() !== "");

            let isFrom =  checkHashTextAndEmail(From);
            let isTo =  checkHashTextAndEmail(To);
            let isCC =  checkHashTextAndEmail(CC);
            let isBCC =  checkHashTextAndEmail(BCC);
            let isFailInit =  checkHashTextAndEmail(FailInit);
            let isSubject =  checkHash(Sub,"sub");
            let isAttachment =  checkHash(Attach,"attach");

            let obj = {
                from:isFrom,
                to:isTo,
                CC:isCC,
                BCC:isBCC,
                Fail:isFailInit,
                sub:isSubject,
                attach:isAttachment
            }
            if (!isFrom && !isTo && !isCC && !isBCC && !isFailInit && !isSubject && !isAttachment) {
                    let notificationtypeArr = state?.notificationtype ?? []
                    let notificTypeObj = {
                        notificType:notificationTypeData?.notificationType,
                        from:From,
                        to:To,
                        CC:CC,
                        BCC:BCC,
                        subject:notificationTypeData?.subject,
                        attachment:notificationTypeData?.attachment.replace(/,$/, ""),
                        body:notificationTypeData?.body,
                        retryCount:notificationTypeData?.retryCount,
                        failureInitTo:FailInit
                    }
                    if(notificationTypeData?.index !==null){
                        let  notifictypeedit = state?.notificationtype
                        notifictypeedit[notificationTypeData?.index] = notificTypeObj
                        setState({
                            ...state,
                            notificationtype: notifictypeedit
                        })
                        setNotificationTypeData({
                            index:null,
                            notificationType: "",
                            from: "",
                            to: "",
                            cc: "",
                            bcc: "",
                            subject: "",
                            body: "",
                            attachment: "",
                            retryCount: "0",
                            failureInitTo: "",
                        })
                        setError({
                            ...error,
                            isFieldError: null,
                            isListError:false,
                        })
                        setisNew(false);
                    } else {
                        notificationtypeArr.push(notificTypeObj);
                        setState({
                            ...state,
                            notificationtype: notificationtypeArr
                        })
                        setNotificationTypeData({
                            index:null,
                            notificationType: "",
                            from: "",
                            to: "",
                            cc: "",
                            bcc: "",
                            subject: "",
                            body: "",
                            attachment: "",
                            retryCount: "0",
                            failureInitTo: "",
                        })
                        setError({
                            ...error,
                            isFieldError: null,
                            isListError:false,
                        })
                        setisNew(false);
                    }
            } else {
                setError({
                    ...error,
                    isFieldError: obj,
                    isListError:false,
                })
                alert?.setSnack({
                    open: true,
                    severity: AlertProps.severity.warning,
                    msg: "Invalid Text/Email !",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                    tone: true,
                });
            }
        } else {
            setError({
                ...error,
                isListError:true,
            })
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Fill all the Mandatory Fields!",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
        }


    }
    const handleChangeList = (type,val) => {
        const hasHtmlTags = /<\/?[^>]+(>|$)/.test(val);
        if(hasHtmlTags && type !== "body") {
            let cleanText = val.replace(/<\/?[^>]+(>|$)/g, "")
            setNotificationTypeData({
                ...notificationTypeData,
                [type]: cleanText 
            })
            return;
        }
        setNotificationTypeData({
            ...notificationTypeData,
            [type]: val 
        })
        if(type === "body"){
            setOpenSchema({...openSchema,isAlert:false,isBody:false})
        }
        if(type === "notificationType" && val?.label === "email"){
            setNotificationTypeData({
                ...notificationTypeData,
                [type]: val,
                from: "" 
            })
        }
        if(type === "notificationType" && val?.label === "push"){
            setNotificationTypeData({
                ...notificationTypeData,
                [type]: val,
                cc: "",
                bcc: "",
            })
        }
    }

    const handleSave = async () => {
        let seqResult;
        let Resp;
        if(state?.alertname &&
            state?.refType?.label &&
            state?.refId?.label &&
            state?.alertschema &&
            state?.notificationtype?.length > 0
        ){
            setIsSaving(true)
            if(!props?.alertNotific?._id){
                let seqGenPayload = {
                    db_name: process.env.REACT_APP_METADATA_DB_NAME,
                    doc: {_key:""},
                    entity: Config.notificationConfig,
                    fieldname: "_key",
                    is_metadata: "false"
                  }
                Resp = await AxiosCall(seqGenPayload,Config.generateSequenceApi)
                seqResult = Resp?.Result
            }
            if(props?.alertNotific?._id || Resp?.Code === 201){
                let alertsArr = []
                state?.notificationtype?.map((v,i)=>{
                    let obj = {
                        alertmode : v?.notificType?._id ?? "",
                        alertfrom : typeof v?.from === "string" ? v?.from : v?.from.join(',') ?? "",
                        alertto: v?.to ?? [],
                        cc: v?.CC ?? [],
                        bcc: v?.BCC ?? [],
                        subject: v?.subject ?? "",
                        body : v?.body ?? "",
                        attachment: v?.attachment ?? "",
                        retrychckcnt: Number(v?.retryCount ?? 0) ?? "",
                        failureinito: v?.failureInitTo ?? []
                    }
                    alertsArr.push(obj)
                })
                let alertSchemaVal = state?.alertschema;
                delete alertSchemaVal?.facts
                delete alertSchemaVal?.label
                let payload = {
                    alertcode: props?.alertNotific?.alertcode ? state?.alertcode : seqResult?.[0]?.Lastvalue ?? "",
                    alertname: state?.alertname ?? "",
                    alertschema: state?.alertschema ?? "",//JSON.stringify(JSON.parse(state?.alertschema)) ?? "",
                    referencetype: {value:"#"+state?.refType?.value+"#",label: state?.refType?.label},
                    referenceid: {value:"#"+state?.refId?.value+"#",label:state?.refId?.label},
                    alerts: alertsArr ?? ""
                }
                let filter = { filter: { _id: state?._id ?? "" } };
                let masterPayload = []
                let data = {
                    db_name: process.env.REACT_APP_METADATA_DB_NAME,
                    entity: Config.notificationConfig,
                    is_metadata: false,
                    metadataId: "",
                    metadata_dbname: "",
                    ...filter,
                    doc: payload
                };
                masterPayload.push(data)
                let Resp = await AxiosCall(masterPayload,process.env.REACT_APP_UPSERT_DOC)
                if(Resp?.Code === 201){
                    setIsSaving(false)
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: props?.alertNotific?._id ? "Alert Notification Updated Successfully!" : "Alert Notification Saved Successfully!",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                    setState({
                        _id: "",
                        notificationtype: [],
                        alertcode: "",
                        alertname: "",
                        refType: "",
                        refId: "",
                        alertschema: "",
                    });
                    setalertschema("")
                    closeForm();
                    props?.setalertNotific({})
                    props?.getAlertNotification(0,10,"");
                }else{
                    setIsSaving(false)
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: props?.alertNotific?._id ? "Failed to Update Alert Notification!" : "Failed to Save Alert Notification!",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                }
            }else{
                setIsSaving(false)
                alert?.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Sequence Failed Please Contact Admin!",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                    tone: true,
                });
            }
        } else { 
            setError({
                ...error,
                iserror:true,
            });
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: state?.alertschema ? state?.alertname && state?.refType?.label && state?.refId?.label &&
            state?.alertschema && state?.notificationtype?.length < 1 ? "Please Add Minimum 1 Notification Type" : 
            "Please Fill all the Mandatory Fields!" :"Please Fill the Schema First!",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
        }
    }
    const handleDelete = (e, data, index) => {
        let list = JSON.parse(JSON.stringify(state?.notificationtype));
        list.splice(index, 1)
        setState({
            ...state,
            notificationtype: list
        })
    }

    const handleEdit = (e, data, index) => {
        let notificTypeData = state?.notificationtype ?? [];
       
        notificTypeData = notificTypeData[index];
        setNotificationTypeData({
            index:index,
            notificationType: notificTypeData?.notificType,
            from: notificTypeData?.from,
            to: notificTypeData?.to?.join(','),
            cc: notificTypeData?.CC?.join(','),
            bcc: notificTypeData?.BCC?.join(','),
            subject: notificTypeData?.subject,
            body: notificTypeData?.body,
            attachment: notificTypeData?.attachment,
            retryCount: notificTypeData?.retryCount,
            failureInitTo: notificTypeData?.failureInitTo?.join(','),
        });
        setisNew(true);
    }

    const checkValidSchema = (inputSchema) => {
        try {
            const ajv = new Ajv();
            const schema = JSON.parse(inputSchema);
            // const draftSchema = {
            //     "$id": "http://json-schema.org/draft-07/schema#",
            //     "type": "object",
            //     "properties": {
            //         "type": { "type": "string" },
            //         "properties": { "type": "object" },
            //         "required": { "type": "array" }
            //     }
            // };
            // ajv.addSchema(draftSchema, "http://json-schema.org/draft-07/schema#");
            const hasSchemaStructure = schema?.type === "object" && schema?.hasOwnProperty("properties") && 
                Object.keys(schema?.properties).every(key => typeof schema.properties[key] === "object");
            //    (schema?.type === "array" && schema?.items && schema?.items?.[0]?.type === "object" && schema?.items?.[0]?.hasOwnProperty("properties"));
            const isValidSchema = hasSchemaStructure && ajv.validateSchema(schema);
        
            if (isValidSchema) {
                let schemaArr = []
                Object.keys(schema?.properties)?.map((v,i)=>{
                    let obj = {
                        label:v,
                        value:v
                    }
                    schemaArr.push(obj);
                })
                setSchemaVal(schemaArr);
                handleChange("alertschema",inputSchema);
                setOpenSchema({
                    ...openSchema,
                    isAlert:false
                });
            } else {
                alert.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Invalid Schema",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                  });
            }
        } catch (e) {
            console.error("Error parsing schema input or validating schema:", e.message);
            alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Invalid Schema!",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
        }
    
    }

    const clearAllNotificationTypes = () =>{
        setState({
            ...state,
            notificationtype:[],
        })
        setNotificationTypeData({
            index:null,
            notificationType: "",
            from: "",
            to: "",
            cc: "",
            bcc: "",
            subject: "",
            body: "",
            attachment: "",
            retryCount: "0",
            failureInitTo: "",
        })
        setwarningAlert(false)
    }
    const checkDuplicateEntry = () => {
        if(String(notificationTypeData?.index)){
            if( state?.notificationtype?.filter((item, i) =>item?.notificType?._id === notificationTypeData?.notificationType?._id 
            && i !== notificationTypeData?.index).length > 0){
                alert?.setSnack({
                    open: true,
                    severity: AlertProps.severity.warning,
                    msg: "Existing Notification Type Can't Added Again!",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                    tone: true,
                });
                return true;
            }
        } else {
            if (state?.notificationtype?.some(item => item.notificType._id === notificationTypeData?.notificationType._id)) {
                alert?.setSnack({
                    open: true,
                    severity: AlertProps.severity.warning,
                    msg: "Existing Notification Type Can't Added Again!",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                    tone: true,
                });
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        getNotificationTypesDD();
        getAlertSchema();
    }, []);

    useEffect(()=>{
        if(props?.alertNotific?._id){
            if(props?.alertNotific?.alerts?.length > 0){
                setisNew(false);
            }
            let alertsArr = []
            props?.alertNotific?.alerts?.map((v,i)=>{
                let obj = {
                    notificType: v?.alertmode ?? {},
                    from: v?.alertfrom ?? "",
                    to: v?.alertto ?? [],
                    CC: v?.cc ?? [],
                    BCC: v?.bcc ?? [],
                    subject: v?.subject ?? "",
                    attachment: v?.attachment ?? "",
                    body: v?.body ?? "",
                    retryCount: String(v?.retrychckcnt ?? ""),//v?.retrychckcnt === 0 ? "0" : Number(v?.retrychckcnt),
                    failureInitTo: v?.failureinito ?? []
                }
                alertsArr.push(obj);
            })
            // let schemaArr = []
            let refTypeObj = props?.alertNotific?.referencetype;
            refTypeObj.value = refTypeObj.value.replace(/^#|#$/g, "");

            let refIdObj = props?.alertNotific?.referenceid;
            refIdObj.value = refIdObj.value.replace(/^#|#$/g, "");
            let alertSchemaObj = props?.alertNotific?.alertschema;
            alertSchemaObj.label = props?.alertNotific?.alertschema?.factname;
            // Object.keys(schema?.properties)?.map((v,i)=>{
            //     let obj = {
            //         label:v,
            //         value:v
            //     }
            //     schemaArr.push(obj);
            // })
            // setSchemaVal(schemaArr);
            setalertschema(props?.alertNotific?.alertschema); 
            setState({
                _id: props?.alertNotific?._id ?? "",
                notificationtype: alertsArr ?? [],
                alertcode: props?.alertNotific?.alertcode ?? "",
                alertname: props?.alertNotific?.alertname ?? "",
                refType: props?.alertNotific?.referencetype ?? "",
                refId: props?.alertNotific?.referenceid ?? "",
                alertschema: alertSchemaObj ?? "",
            })
        }
    },[props?.alertNotific])

    const { labelStyle, borderStyle, errorBorderStyle } = styles;
    const { parent_id, closeForm, editData, isView, isEdit } = props;

    return (
        <React.Fragment>
            <CommonTabList
                parent_id={"timetableform"}
                backbtn
                backFun={closeForm}
                title={
                    isView
                        ? "View Alert Notification"
                        : editData
                            ? "Edit Alert Notification"
                            : "Add Alert Notification"
                }
                list={[]}
            />
            <Div
                id={`${parent_id}-parent-div`}
                className="pi-root"
                style={{ margin: "20px" }}
            >
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
                            <Div id={`${parent_id}-parent-sub-div`}>
                                <H6 id={`${parent_id}-title-h6`} className="pi-title">
                                    {"Alert Notification"}
                                </H6>
                            </Div>
                            <Row>
                                <Col
                                    md={2}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                    inLineStyles={{ padding: 10 }}
                                >
                                    <TextInput
                                        value={state?.alertcode}
                                        onChange={(e) => {
                                            if (/^[0-9a-zA-Z-]*$/.test(e.target.value)) {
                                                // handleFormState("alertcode", e.target.value)
                                            }
                                        }}
                                        label="Alert Code"
                                        labelStyle={labelStyle}
                                        placeholder="Alert code"
                                        disabled={true}
                                        style={error?.alertcode ? errorBorderStyle : borderStyle}
                                    />
                                </Col>
                                <Col
                                    md={2}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                    inLineStyles={{ padding: 10 }}
                                >
                                    {/* <Grid container justifyContent="space-between" style={{
                                        padding: "6px",
                                        borderRadius: "5px",
                                        border: error?.iserror && !state?.alertschema ? "1px solid red" : "2px solid #d3d3d3",
                                    }}
                                    onClick={()=>{
                                        !isView && setOpenSchema({...openSchema,isAlert:true,isBody:false})
                                    }}
                                    >
                                        <Grid item>
                                        <Grid container style={{paddingTop:"5px"}}> 
                                            <Grid item >
                                                <Typography style={{
                                                color: "#6F6F6F",
                                                fontSize: "13px",
                                                marginLeft:"3px",
                                                // marginBottom: "6px",
                                                fontFamily: `pc_regular !important`}}
                                                >
                                                    Alert Schema
                                                <span style={{ color: "red" }}> *</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {state?.alertschema && (
                                                    <Image
                                                        id={`${parent_id}-tick-image`}
                                                        style={{ width: 16, height: 16, marginLeft: 5 }}
                                                        src="images/icons8-ok.svg"
                                                        alt="tick"
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                            {state?.alertschema ? (
                                                <Grid item style={{paddingTop:"2px"}}>   
                                                    <EditOutlinedIcon onClick={()=>  { !isView && setOpenSchema({
                                                                    ...openSchema,
                                                                    isAlert:true,
                                                                    isBody:false,
                                                                })}}/>
                                                </Grid>
                                            ) : (
                                                <Grid item style={{paddingTop:"2px"}}>   
                                                    <AddCircleOutlineOutlinedIcon onClick={()=>  { !isView && setOpenSchema({
                                                                    ...openSchema,
                                                                    isAlert:true,
                                                                    isBody:false,
                                                                })}}/>
                                                </Grid>
                                            )}
                                    </Grid> */}
                                    <TextInput
                                        value={state?.alertname}
                                        onChange={(e) => {
                                            if (/^[0-9a-zA-Z\s@\/]*$/.test(e.target.value)) {
                                                handleChange("alertname", e.target.value)
                                            }
                                        }}
                                        label="Alert Name"
                                        isRequired={true}
                                        disabled={isView}
                                        placeholder="Enter alert name"
                                        labelStyle={labelStyle}
                                        style={{
                                            ...error?.iserror && !state?.alertname ? errorBorderStyle : borderStyle,
                                            border: error?.iserror && !state?.alertname ? "1px solid red" : "2px solid #d3d3d3"
                                        }}
                                        
                                    /> 
                                </Col>
                                <Col
                                    md={2}
                                    lg={4}
                                    sm={6}
                                    xs={12}
                                    inLineStyles={{ padding: 10 }}
                                >
                                     <Typography
                                        style={{
                                            fontSize: "13px",
                                            Fontfamily: "pc_regular",
                                            color: "#6F6F6F",
                                        }}
                                        variant="body1"
                                    >
                                        Alert Schema<span style={{ color: "red" }}> *</span>
                                    </Typography>
                                    <SelectBox
                                        id={`${parent_id}-facilitytype-selectBox`}
                                        list={alertSchemaList ?? []}
                                        placeholder={"select..."}
                                        disableClearable
                                        disabled={isView || isEdit}
                                        value={state?.alertschema}
                                        error={error?.iserror && !state?.alertschema?.label}
                                        onchange={(e,v) => handleChange("alertschema",v)}
                                    />
                                </Col>
                                <Col
                                    md={2}
                                    lg={4}
                                    sm={6}
                                    xs={12}
                                    inLineStyles={{ padding: 10 }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: "13px",
                                            Fontfamily: "pc_regular",
                                            color: "#6F6F6F",
                                        }}
                                        variant="body1"
                                    >
                                        Reference Type<span style={{ color: "red" }}> *</span>
                                    </Typography>
                                    <SelectBox
                                        id={`${parent_id}-facilitytype-selectBox`}
                                        list={schemaVal ?? []}
                                        placeholder={"select..."}
                                        disableClearable
                                        disabled={isView}
                                        value={state?.refType}
                                        error={error?.iserror && !state?.refType?.label}
                                        onchange={(e,v) => handleChange("refType",v)}
                                    />
                                </Col>
                                <Col
                                    md={2}
                                    lg={4}
                                    sm={6}
                                    xs={12}
                                    inLineStyles={{ padding: 10 }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: "13px",
                                            Fontfamily: "pc_regular",
                                            color: "#6F6F6F",
                                        }}
                                        variant="body1"
                                    >
                                        Reference Id<span style={{ color: "red" }}> *</span>
                                    </Typography>
                                    <SelectBox
                                        id={`${parent_id}-facilitytype-selectBox`}
                                        list={schemaVal ?? []}
                                        placeholder={"select..."}
                                        disableClearable
                                        disabled={isView}
                                        value={state?.refId}
                                        error={error?.iserror && !state?.refId?.label}
                                        onchange={(e,v) => handleChange("refId",v)}
                                    />
                                </Col>
                            </Row>
                            <Row>

                            </Row>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <React.Fragment>
                            <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
                            <Grid container justifyContent='space-between'>
                                <Grid item >
                                    <Div id={`${parent_id}-parent-sub-div`}>
                                        <H6 id={`${parent_id}-title-h6`} className="pi-title">
                                            {"NOTIFICATION TYPES"}
                                        </H6>
                                    </Div>
                                </Grid>
                                {!isView && (   
                                <Grid item> 
                                    <Div id={`${parent_id}-parent-sub-div`} onClick={()=>setisNew(true)}>
                                        <Button
                                        id={`${parent_id}-Add-New-button`}
                                        className={classes.addBtn}
                                        onClick={()=>{setisNew(true);setNotificationTypeData({
                                            index:null,
                                            notificationType: "",
                                            from: "",
                                            to: "",
                                            cc: "",
                                            bcc: "",
                                            subject: "",
                                            body: "",
                                            attachment: "",
                                            retryCount: "0",
                                            failureInitTo: "",
                                        })}}
                                        color="primary"
                                        >
                                            + Add New
                                        </Button>
                                    </Div>
                                </Grid>
                                )}

                            </Grid>

                            {isNew && (
                                <>
                                    <Grid container spacing={2} xs={12} alignItems='flex-start'>
                                        <Grid item xs={12}>
                                            <Typography
                                            style={{
                                                fontSize: "13px",
                                                Fontfamily: "pc_regular",
                                                color: "#6F6F6F",
                                            }}
                                            variant="body1"
                                            >
                                                Notification Type<span style={{ color: "red" }}> *</span>
                                            </Typography>
                                            <SelectBox
                                            id={`${parent_id}-facilitytype-selectBox`}
                                            list={extractIdAndValue("ALERTNOTIFICATIONTYPE",notificationTypes)}
                                            placeholder={"select..."}
                                            // disableClearable
                                            error={error?.isListError && !notificationTypeData?.notificationType?.label}
                                            value={notificationTypeData?.notificationType}
                                            onchange={(e,v) => handleChangeList("notificationType",v)}
                                            />
                                        </Grid>
                                        {notificationTypeData?.notificationType?.label !== "email" && (
                                        <Grid item xs={6}>
                                            <Grid>
                                                <Typography variant={"body1"} style={labelStyle}>From Recipient<span style={{ color: "red" }}> *</span></Typography>
                                                <RichText
                                                    error={(notificationTypeData?.notificationType?.label !== "email" 
                                                    && error?.isListError && !notificationTypeData?.from) || error?.isFieldError?.from ? true : false}
                                                    withDirectionTags
                                                    initialValue={notificationTypeData?.from}
                                                    schemaVal={schemaVal}
                                                    name="From Recipient"
                                                    placeholder="Enter From Recipient"
                                                    checktype="mail"
                                                    onChange={(rawValue) => {
                                                        // if (/^[a-zA-Z0-9@.]+$/.test(rawValue)){
                                                            handleChangeList("from", rawValue)
                                                        // }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        )}
                                        <Grid item xs={6}>
                                            <Grid>
                                                <Typography variant={"body1"} style={labelStyle}>To Recipient<span style={{ color: "red" }}> *</span></Typography>
                                                <RichText
                                                    error={(error?.isListError && !notificationTypeData?.to) || error?.isFieldError?.to ? true : false}
                                                    withDirectionTags
                                                    initialValue={notificationTypeData?.to}
                                                    schemaVal={schemaVal}
                                                    name="TO Recipient"
                                                    placeholder="Enter To Recipient"
                                                    onChange={(rawValue) => handleChangeList("to", rawValue)}
                                                />
                                            </Grid>
                                        </Grid>
                                        {notificationTypeData?.notificationType?.label === "email" && (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid>
                                                        <Typography variant={"body1"} style={labelStyle}>CC Recipient</Typography>
                                                        <RichText
                                                            error={error?.isFieldError?.CC}
                                                            withDirectionTags
                                                            initialValue={notificationTypeData?.cc}
                                                            schemaVal={schemaVal}
                                                            name="CC Recipient"
                                                            placeholder="Enter CC Recipient"
                                                            onChange={(rawValue) => handleChangeList("cc", rawValue)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid>
                                                        <Typography variant={"body1"} style={labelStyle}>BCC Recipient</Typography>
                                                        <RichText
                                                            error={error?.isFieldError?.BCC}
                                                            withDirectionTags
                                                            initialValue={notificationTypeData?.bcc}
                                                            name="BCC Recipient"
                                                            placeholder="Enter BCC Recipient"
                                                            schemaVal={schemaVal}
                                                            onChange={(rawValue) => handleChangeList("bcc", rawValue)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={6}>
                                            <Grid>
                                                <Typography variant={"body1"} style={labelStyle}>Subject<span style={{ color: "red" }}> *</span></Typography>
                                                <RichText
                                                    error={(error?.isListError && !notificationTypeData?.subject) || error?.isFieldError?.sub ? true : false}
                                                    withDirectionTags
                                                    initialValue={notificationTypeData?.subject}
                                                    name="Subject"
                                                    placeholder="Enter Subject"
                                                    schemaVal={schemaVal}
                                                    onChange={(rawValue) => handleChangeList("subject", rawValue)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid>
                                                <Typography variant={"body1"} style={labelStyle}>Attachment</Typography>
                                                <RichText
                                                    error={error?.isFieldError?.attach}
                                                    withDirectionTags
                                                    initialValue={notificationTypeData?.attachment}
                                                    schemaVal={schemaVal}
                                                    name="Attachment"
                                                    placeholder="Attachment"
                                                    onChange={(rawValue) => handleChangeList("attachment", rawValue)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} style={{marginTop:"20px"}}>
                                            <Grid container justifyContent="space-between" style={{
                                                padding: "6px",
                                                borderRadius: "5px",
                                                border: error?.isListError && !notificationTypeData?.body ? "1px solid red" : "2px solid #d3d3d3"
                                            }}
                                            onClick={()=>setOpenSchema({
                                                    ...openSchema,
                                                    isAlert:false,
                                                    isBody:true,
                                                })}
                                            >
                                                <Grid item>
                                                <Grid container style={{paddingTop:"5px"}}> 
                                                    <Grid item >
                                                        <Typography style={{
                                                        color: "#6F6F6F",
                                                        fontSize: "15px",
                                                        // marginBottom: "6px",
                                                        marginLeft:"3px",
                                                        fontFamily: `pc_regular !important`}}
                                                        >
                                                            Body
                                                        <span style={{ color: "red" }}> *</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {notificationTypeData?.body && (
                                                            <Image
                                                                id={`${parent_id}-tick-image`}
                                                                style={{ width: 16, height: 16, marginLeft: 5 }}
                                                                src="images/icons8-ok.svg"
                                                                alt="tick"
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                </Grid>
                                                    {notificationTypeData?.body ? (
                                                        <Grid item style={{paddingTop:"2px"}}>   
                                                            <EditOutlinedIcon onClick={()=>  setOpenSchema({
                                                                                ...openSchema,
                                                                                isBody:true,
                                                                                isAlert:false,
                                                                            })}/>
                                                        </Grid>
                                                    ) : (
                                                        <Grid item style={{paddingTop:"2px"}}>   
                                                            <AddCircleOutlineOutlinedIcon onClick={()=>  setOpenSchema({
                                                                                ...openSchema,
                                                                                isBody:true,
                                                                                isAlert:false,
                                                                            })}/>
                                                        </Grid>
                                                    )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                                <TextInput
                                                    value={notificationTypeData?.retryCount}
                                                    onChange={(e) => {
                                                        if (/^[0-9\s@\/]*$/.test(e.target.value)) {
                                                            handleChangeList("retryCount", e.target.value)
                                                        }
                                                    }}
                                                    label="Retry Count"
                                                    isRequired={true}
                                                    placeholder="Enter Retry Count"
                                                    labelStyle={labelStyle}
                                                    style={{
                                                        ...error?.alertname ? errorBorderStyle : borderStyle,
                                                        border: error?.isListError && !notificationTypeData?.retryCount ? "1px solid red" :"2px solid #d3d3d3",
                                                    }}
                                                />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid>
                                                <Typography variant={"body1"} style={labelStyle}>Failure Init To</Typography>
                                                <RichText
                                                    error={error?.isFieldError?.Fail}
                                                    withDirectionTags
                                                    initialValue={notificationTypeData?.failureInitTo}
                                                    name="Failure Init To"
                                                    placeholder="Enter Failure Init To"
                                                    schemaVal={schemaVal}
                                                    onChange={(rawValue) => handleChangeList("failureInitTo", rawValue)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} xs={12} justifyContent='flex-end'>
                                        <Grid item>
                                            <Button
                                                id={"agerange_form_rangedetails_Cancel_button"}
                                                onClick={()=>setisNew(false)}
                                                style={{ float: "right", marginRight: "20px" }}
                                                variant="outlined"
                                            >
                                                Clear
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                id={"agerange_form_rangedetails_Save_button"}
                                                onClick={()=>{
                                                    if(!checkDuplicateEntry()){
                                                        addNotificationTypes()
                                                    } 
                                                }}
                                                style={{ float: "right" }}
                                                variant="contained"
                                                color="primary"
                                            >
                                                {"Add"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                                {!isNew && (
                                <Grid
                                    id={`${parent_id}_table_grid`}
                                    container
                                    className={classes.form}
                                >
                                    <CommonTable
                                        parent_id={"category_details"}
                                        handleEdit={(e, data, index) =>
                                            handleEdit(e, data, index)
                                        }
                                        handleDelete={handleDelete}
                                        rightAlign={["Action"]}
                                        Header={["NotificationType", "Subject", "To", "CC", !isView && "Action"]}
                                        dataList={state?.notificationtype ?? []}
                                        tableData={[
                                            // {
                                            //     type: ["TEXT"],
                                            //     name: "notificationtype"
                                            // },
                                            { type: ["TEXT"], name: "notificType", optionLabel: "label" },
                                            { type: ["TEXT"], name: "subject" },
                                            { type: ["TEXT"], name: "to" },
                                            { type: ["TEXT"], name: "CC" },
                                            // { type: ["TEXT"], name: "bcc" },
                                            // { type: ["TEXT"], name: "body" },
                                            isView ? {} : { type: ["EDIT", "DELETE"], name: "", align: "right" },
                                        ]}
                                    />
                                </Grid>
                                )}
                            </Paper>
                        </React.Fragment>
                    </Grid>
                    {isView ? null : (
                        <Grid
                            id={"agerange-form-rangedetails-grid"}
                            style={{ padding: "20px" }}
                        >
                        {/* {!isNew && ( */}
                            <>
                                <Button
                                    id={"agerange_form_rangedetails_Save_button"}
                                    onClick={()=> isSaving ? null : handleSave()}
                                    style={{ float: "right" }}
                                    variant="contained"
                                    color="primary"
                                >
                                    {props?.alertNotific?._id ? isSaving ? "Updating..." : "Update" : isSaving  ? "Saving..." :"Save"}
                                </Button>
                                <Button
                                    id={"agerange_form_rangedetails_Cancel_button"}
                                    onClick={closeForm}
                                    style={{ float: "right", marginRight: "20px" }}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                            </>
                        {/* )} */}
                        </Grid>
                    )}
                </Grid>
            </Div>
            <Modal
                open={openSchema?.isAlert || openSchema?.isBody}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                onClose={()=>  setOpenSchema({
                    ...openSchema,
                    isAlert:false,
                    isBody:false
                })}
            >
                {openSchema?.isAlert && !openSchema?.isBody? (
                <Paper className={classes.modalPaper}>
                    <div className={classes.modalHeader}>
                        <Typography style={{ fontFamily: "pc_semibold" }}>{"Alert Schema"}</Typography>

                        <ALLMCORE.IconButton
                            size="small"
                            onClick={() => setOpenSchema({
                                ...openSchema,
                                isAlert:false,
                                isBody:false
                            })}
                        >
                            <CloseRoundedIcon htmlColor="rgba(1, 32, 92, 1)" />
                        </ALLMCORE.IconButton>
                    </div>

                    <Divider className={classes.modalHeaderDivider} />

                    <div className={classes.modalBodyContainer}>
                        <div className={classes.modalInputContainer}>
                            <TextArea
                                value={alertSchema}
                                onChange={(e) => {
                                    // if (/^[0-9a-zA-Z\s@\/]*$/.test(e.target.value)) {
                                        setalertschema(e.target.value); 
                                    // }
                                }}
                                // label="Alert Schema"
                                isRequired={true}
                                placeholder="Enter alert schema"
                                labelStyle={labelStyle}
                                // disabled={editData || isView ? true : false}
                                style={{
                                    ...error?.alertschema ? errorBorderStyle : borderStyle,
                                    height: "250px",  
                                    resize: "vertical"
                                }}
                            />
                        </div>

                        <div className={classes.modalActionContainer}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                // children={state?.editIndex ? "Update" : "Add"}
                                style={qdmstyles.CPPs}
                                size="small"
                                // onClick={() => handleSaveClick(state?.editIndex, false)}
                                className={classes.modalAdd}
                                onClick={() => checkValidSchema(alertSchema)}
                            >
                                {state?.editIndex ? "Update" : "Add"}
                            </Button>
                        </div>
                    </div>
                </Paper>
                ) : openSchema?.isBody && !openSchema?.isAlert ? (
                <Paper className={classes.modalPaper}>
                    <div className={classes.modalHeader}>
                        <Typography style={{ fontFamily: "pc_semibold" }}>{"Body"}</Typography>

                        <ALLMCORE.IconButton
                            size="small"
                            onClick={() => setOpenSchema({
                                ...openSchema,
                                isAlert:false,
                                isBody:false
                            })}
                        >
                            <CloseRoundedIcon htmlColor="rgba(1, 32, 92, 1)" />
                        </ALLMCORE.IconButton>
                    </div>

                    <Divider className={classes.modalHeaderDivider} />

                    <div className={classes.modalBodyContainer}>
                        <div className={classes.modalInputContainer}>
                        <Grid>
                            {/* <Typography variant={"body1"} style={labelStyle}>Body</Typography> */}
                            <RichText
                                withDirectionTags
                                initialValue={notificationTypeData?.body}
                                schemaVal={schemaVal}
                                name="Body"
                                placeholder="Enter Body Content"
                                onChange={(rawValue) => setBody(rawValue)}
                            />
                        </Grid>
                        </div>

                        <div className={classes.modalActionContainer}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                // children={state?.editIndex ? "Update" : "Add"}
                                style={qdmstyles.CPPs}
                                size="small"
                                // onClick={() => handleSaveClick(state?.editIndex, false)}
                                className={classes.modalAdd}
                                onClick={() =>  openSchema?.isBody ? handleChangeList("body",body) : checkValidSchema(alertSchema) }
                            >
                                {props?.alertNotific?._id ? "Update" : "Add"}
                            </Button>
                        </div>
                    </div>
                </Paper>
                ) : null}
            </Modal>
            <Dialog
                open={warningAlert}
                keepMounted
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title" >
                    <Typography style={{ display: 'flex' }}>
                    <ErrorOutlineOutlinedIcon style={{ width: 22, height: 32, marginRight: 7, color: "#F58B00" }}/>
                    <Typography variant='span' style={{ paddingTop: 5, fontWeight: "bold",}}>{"Warning"}</Typography>
                    </Typography>
                    <CloseIcon onClick={() => setwarningAlert(false)} style={{ float: "right", marginTop: "-26px", cursor: "pointer" }} />
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-description" style={{ border: `1.5px solid "#F58B00"`, borderRadius: "8px" }}>
                        <Typography style={{
                            background: "#FEF3E5",
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            padding: "8px 11px", color: "#F58B00",
                            fontWeight: 800, minWidth: 500
                        }}>Message</Typography>
                        <Typography style={{ padding: "10px 10px 15px 10px" }}>
                            {"Changing the alert schema will result in the loss of any saved notification types data. Please proceed carefully."}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: "5px 23px 17px", justifyContent: "flex-end" }}>
                    <Button variant='contained' onClick={() => clearAllNotificationTypes()} style={{ color: "white", background: "#F58B00" }}>
                        {"I Understand & Clear all"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default NotificationEditView;
