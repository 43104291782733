import React from "react";
import {
  Div,
  Paper,
  //Select,
  TextInput,
  H6,
  Col,
  Row,
  Text,
  Divider,
  Upload,
  Image,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch, Avatar } from "@material-ui/core";
import { uploadImage, getImgUrl, AlertProps, readFileAsBase64 } from "../../../utils";
//import { Typography } from "@material-ui/core";
import { SelectWithLabel } from "../../../components"

import { Typography } from "@material-ui/core";
//import { DateTimePicker } from "../../common/smartForm/component";

import CustomInputDatePicker from "../../common/datePicker/inputDatePicker";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 37,
    height: 21,
    padding: 0,
    margin: theme.spacing(1),
    marginTop: "-1px",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0071F2",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
      checked={!!props?.checked}
    />
  );
});

class PatientNames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: true,
    };
  }

  onChangeState = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
  };

  setData = (name, value, index, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = this.props[this.props.saveto];
    patientData.names[index][name] = value;
    if (patientData.names[index].error) {
      patientData.names[index].error[name] = false;
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  setDetails = (name, value, next, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = this.props[this.props.saveto];
    patientData.details[name] = value;
    if (patientData.details?.error) {
      patientData.details.error[name] = false;
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  newRowAdd = () => {
    let patientData = this.props[this.props.saveto];
    patientData.names.push({});
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  getSize = (size) => {
    let fileSize = size / 1024 / 1024;
    if (fileSize < 1) {
      return `${parseInt(size / 1024) + 1} KB`;
    } else {
      return `${parseInt(fileSize)} MB`;
    }
  };
  handleDelete = (i) => {
    let patientData = this.props[this.props.saveto];
    patientData.names.splice(i, 1);
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  handleLimit = () => {
    this.setState({
      limited: !this.state.limited,
    });
  };

  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  setDataUpload = async (name, value, next, limit) => {
    if (!value.type.includes("image")) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Select an Image with size less than 1MB.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return false;
    }
    let UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let base64string = await readFileAsBase64(value);

    UploadedFiles = await uploadImage([value]);
    let patientData = this.props[this.props.saveto];
    if (UploadedFiles.length > 0) {
      patientData.details[name] = {
        fileName: UploadedFiles[0].filename ?? "",
        fileid: UploadedFiles[0].fileid ?? "",
        filetype: UploadedFiles[0].filetype ?? "",
        objectid: UploadedFiles[0].objectid ?? "",
        base64string:  base64string ?? "",
      };
    }

    // if (patientData.details[name].error) {
    //   patientData.details[name].error = false;
    // }
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  render() {
    const { names, details } = this.props[this.props.saveto];
    const { labelStyle, borderStyle, errorBorderStyle } = this.styles;
    const { parent_id } = this.props;

    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Row
          id={`${parent_id}-parent-row`}
          className={"pn-row-root"}>
          <Col
            id={`${parent_id}-upload-col`}
            className="pn-image-root">
            <Paper
              id={`${parent_id}-upload-paper`}
              className="pi-paper pn-image-paper-root">
              <Div
                id={`${parent_id}-upload-div`}
              >
                {details?.photo?.fileid ? (
                  <>
                    <Avatar
                      id={`${parent_id}-phott-avatar`}
                      alt={details?.photo?.fileName}
                      variant={"square"}
                      style={{ width: "100%", height: "100%" }}
                      src={getImgUrl(details?.photo?.fileid)}
                    />
                    <Div
                      id={`${parent_id}_phott_btn_div`}
                      className="pn-upload-actions"
                    >
                      <Upload
                        id={`${parent_id}-phott-btn`}
                        handleChange={(e, value) =>
                          this.setDataUpload("photo", value)
                        }
                        label="Replace Image"
                        labelStyle={{
                          fontSize: 12,
                          fontFamily: 'pc_medium !important',
                        }}
                        className={"pn-upload-actions-btn"}
                        showIcon={false}
                        accept="image/*"
                      />
                    </Div>
                  </>
                ) : (
                  <Upload
                    id={`${parent_id}-upload-image-upload`}
                    label="Upload Image"
                    className={"pn-image"}
                    labelStyle={{
                      fontSize: 14,
                      letterSpacing: "0px",
                      color: "#6F6F6F !important",
                      backgroundColor: "#00000014 !important",
                      height: "146px",
                      width: "150px",
                      paddingRight: "10px",
                      paddingTop: "63px",
                      textAlign: "center",
                    }}
                    inLineStyles={{ padding: 0, width: "100%" }}
                    showIcon={false}
                    handleChange={(e, value) =>
                      this.setDataUpload("photo", value)
                    }
                  // imgUrl="https://fileupload.dev.ainqaplatform.in/primarycare/10204"
                  />
                )}
              </Div>
              <Div
                id={`${parent_id}-practitoner-id-div`}
                className="pn-upload-actions">
                {/* <Button className="pn-upload-actions-btn" name="Upload Image" /> */}
                <TextInput
                  id={`${parent_id}-practitonerid-textinput`}
                  // handleChange={(e, value) =>
                  //   this.setDetails("image", { url: e, file: value })
                  // }
                  // label="Practetioner Id"
                  onChange={(e) =>
                    this.setDetails("practitoner_id", e.target.value, null, 10)
                  }
                  value={details?.practitoner_id ?? ""}
                  placeholder="Practitioner Id *"
                  labelStyle={{
                    fontSize: 14,
                    letterSpacing: "0px",
                    color: "#5F84C0 !important",
                    backgroundColor: "#F5FAFF !important",
                  }}
                  disabled={true}
                  style={
                    borderStyle
                  }
                  className={"pn-upload-actions-btn"}
                  showIcon={false}
                />
              </Div>
            </Paper>
          </Col>
          <Col
            id={`${parent_id}-parent-col`}
            className="pn-names-root">
            <Paper
              id={`${parent_id}-parent-paper`}
              className="pi-paper">
              <Div
                id={`${parent_id}-title-div`}
              >
                <H6
                  id={`${parent_id}-title-h6`}
                  className="pi-title">
                  {this.props.saveto === "nextOfKin"
                    ? "NEXT OF KIN NAME"
                    : "PRACTITIONER DETAILS"}
                </H6>
                {this.props.saveto !== "nextOfKin" && (
                  <Text
                    id={`${parent_id}-Add-New-text`}
                    onClick={() => this.newRowAdd()} className="pi-addbtn">
                    + Add New
                  </Text>
                )}
              </Div>
              {/* {JSON.stringify(names.length)} */}
              {names
                ?.slice(0, this.state.limited ? names.length : 1)
                ?.map((val, i) => (
                  <Row
                    id={`${parent_id}-${i}-limited-row`}
                    className="pi-actions-root pi-names-root">
                    <Col
                      id={`${parent_id}-${i}-limited-col`}
                      className="no-padding-left" style={{ padding: "0" }}>
                      {/* <Select
                        value={val?.use?.label ?? null}
                        // helperText={
                        //   val?.error && val?.error["use"] ? "Is required" : ""
                        // }
                        isRequired={true}
                        getOptionLabel="label"
                        options={this?.props?.allMasters?.["use"]}
                        error={val?.error && val?.error["use"] ? true : false}
                        onChangeValue={(e) => this.setData("use", e, i)}
                        label="Use"
                        showArrow
                        placeholder="use"
                        labelStyle={labelStyle}
                        inLineStyles={
                          val?.error?.use ? errorBorderStyle : borderStyle
                        }
                        hoverColor="#0071F2"
                      /> */}
                      {/* <Typography
                        style={{
                          fontSize: "12px",

                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                        }}
                        variant="body1"
                      >
                        Use<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                        list={this?.props?.allMasters?.["use"] ?? []}
                        placeholder={"use"}
                        disableClearable
                        value={val?.use}
                        onchange={(e, value) => this.setData("use", value, i)}
                      /> */}
                      <SelectWithLabel
                        parent_id={'use'}
                        options={this?.props?.allMasters?.["use"] ?? []}
                        label={"Use"}
                        required={true}
                        disableClearable
                        value={val?.use}
                        onChange={(value) => this.setData("use", value, i)}
                        placeholder={"Select"}
                        // onChange={(data)=>props?.onChangeState('class',data)}
                        error={val?.error?.["use"] ?? false}
                      />
                    </Col>
                    <Col
                      id={`${parent_id}-${i}-Prefix-col`}
                      className="no-padding-left">
                      {/* <Select
                        value={val?.prefix?.label ?? null}
                        onChangeValue={(e) => this.setData("prefix", e, i)}
                        getOptionLabel="label"
                        options={this?.props?.allMasters?.["prefix"]}
                        // helperText={
                        //   val?.error && val?.error["prefix"]
                        //     ? "Is required"
                        //     : ""
                        // }
                        isRequired={true}
                        error={
                          val?.error && val?.error["prefix"] ? true : false
                        }
                        label="Prefix"
                        showArrow
                        placeholder="prefix"
                        labelStyle={labelStyle}
                        inLineStyles={
                          val?.error?.prefix ? errorBorderStyle : borderStyle
                        }
                        hoverColor="#0071F2"
                      /> */}

                      {/* <Typography
                        style={{
                          fontSize: "12px",

                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                        }}
                        variant="body1"
                      >
                        Prefix<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                        list={this?.props?.allMasters?.["prefix"] ?? []}
                        placeholder={"Prefix"}
                        disableClearable
                        value={val?.prefix}
                        onchange={(e, value) =>
                          this.setData("prefix", value, i)
                        }
                      /> */}

                      <SelectWithLabel
                        parent_id={'Prefix'}
                        options={this?.props?.allMasters?.["prefix"] ?? []}
                        label={"Prefix"}
                        required={true}
                        disableClearable
                        value={val?.prefix}
                        onChange={(value) => this.setData("prefix", value, i)}
                        placeholder={"Select"}
                        // onChange={(data)=>props?.onChangeState('class',data)}
                        error={val?.error?.["prefix"] ?? false}
                      />

                    </Col>
                    <Col
                      id={`${parent_id}-${i}-first-name-col`}
                      className="no-padding-left" style={{ padding: "0" }}>
                      <TextInput
                        id={`${parent_id}-${i}-first-name-textinput`}
                        // helperText={
                        //   val?.error && val?.error["first_name"]
                        //     ? "Is required"
                        //     : ""
                        // }
                        value={val.first_name ? val.first_name : ""}
                        onChange={(e) =>
                          this.setData("first_name", e.target.value, i, 20)
                        }
                        isRequired={true}
                        error={
                          val?.error && val?.error["first_name"] ? true : false
                        }
                        label="First Name"
                        labelStyle={labelStyle}
                        // style={borderStyle}
                        placeholder="First Name"
                        onKeyPress={function (e) {
                          var key = e.which;
                          if (
                            (key >= 65 && key <= 90) ||
                            (key >= 95 && key <= 122)
                          ) {
                            return;
                          } else {
                            e.preventDefault();
                          }
                        }}
                        hoverColor="#0071F2"
                        style={
                          val?.error?.first_name
                            ? errorBorderStyle
                            : borderStyle
                        }
                      />
                    </Col>
                    <Col
                      id={`${parent_id}-${i}-middle-name-col`}
                      className="no-padding-left">
                      <TextInput
                        id={`${parent_id}-${i}-middle-name-textinput`}
                        value={val.middle_name ? val.middle_name : ""}
                        onChange={(e) =>
                          this.setData("middle_name", e.target.value, i, 20)
                        }
                        isRequired={true}
                        error={
                          val?.error && val?.error["middle_name"] ? true : false
                        }
                        placeholder="Middle Name"
                        onKeyPress={function (e) {
                          var key = e.which;
                          if (
                            (key >= 65 && key <= 90) ||
                            (key >= 95 && key <= 122)
                          ) {
                            return;
                          } else {
                            e.preventDefault();
                          }
                        }}
                        label="Middle Name"
                        labelStyle={labelStyle}
                        style={
                          val?.error?.middle_name
                            ? errorBorderStyle
                            : borderStyle
                        }
                        hoverColor="#0071F2"
                      />
                    </Col>
                    <Col
                      id={`${parent_id}-${i}-last-name-col`}
                      className="no-padding-left" style={{ padding: "0" }}>
                      <TextInput
                        id={`${parent_id}-${i}-last-name-textinput`}
                        value={val.last_name ? val.last_name : ""}
                        onChange={(e) =>
                          this.setData("last_name", e.target.value, i, 20)
                        }
                        // helperText={
                        //   val?.error && val?.error["last_name"]
                        //     ? "Is required"
                        //     : ""
                        // }
                        isRequired={true}
                        onKeyPress={function (e) {
                          var key = e.which;
                          if (
                            (key >= 65 && key <= 90) ||
                            (key >= 95 && key <= 122)
                          ) {
                            return;
                          } else {
                            e.preventDefault();
                          }
                        }}
                        error={
                          val?.error && val?.error["last_name"] ? true : false
                        }
                        placeholder="Last Name"
                        label="Last Name"
                        labelStyle={labelStyle}
                        style={
                          val?.error?.last_name ? errorBorderStyle : borderStyle
                        }
                        hoverColor="#0071F2"
                      />
                    </Col>
                    {/* <Col
                      id={`${parent_id}-${i}-suffix-col`}
                      className="no-padding-left"> */}
                      {/* <Select
                        value={val?.suffix?.label ?? null}
                        onChangeValue={(e) => this.setData("suffix", e, i)}
                        getOptionLabel="label"
                        options={this?.props?.allMasters?.["suffix"]}
                        // helperText={
                        //   val?.error && val?.error["suffix"]
                        //     ? "Is required"
                        //     : ""
                        // }
                        isRequired={true}
                        error={
                          val?.error && val?.error["suffix"] ? true : false
                        }
                        label="Suffix"
                        showArrow
                        placeholder="suffix"
                        labelStyle={labelStyle}
                        inLineStyles={
                          val?.error?.suffix ? errorBorderStyle : borderStyle
                        }
                        hoverColor="#0071F2"
                      /> */}

                      {/* <Typography
                        style={{
                          fontSize: "12px",

                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                        }}
                        variant="body1"
                      >
                        Suffix<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                        list={this?.props?.allMasters?.["suffix"] ?? []}
                        placeholder={"suffix"}
                        disableClearable
                        value={val?.suffix}
                        onchange={(e, value) =>
                          this.setData("suffix", value, i)
                        }
                      /> */}

                      {/* <SelectWithLabel
                        parent_id={'Suffix'}
                        options={this?.props?.allMasters?.["suffix"] ?? []}
                        label={"Suffix"}
                        required={true}
                        disableClearable
                        value={val?.suffix}
                        onChange={(value) => this.setData("suffix", value, i)}
                        placeholder={"Select"}
                        // onChange={(data) => props?.onChangeState('class', data)}
                        error={val?.error?.["suffix"] ?? false}
                      />
                    </Col> */}
                    <Col
                      id={`${parent_id}-${i}-trash-col`}
                      style={{
                        margin: "auto",
                        padding: 0,
                      }}
                    >
                      <Div
                        id={`${parent_id}-${i}-trash-div`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 18,
                        }}
                      >
                        {/* <i className="fa fa-trash-o" style={{color:'#FF4D4A',cursor:'pointer'}} aria-hidden="true"></i> */}
                        {names?.length > 1 && i !== 0 && (
                          <Div
                            id={`${parent_id}-${i}-trash-sub-div`}
                            style={{
                              width: 15,
                              height: 18,
                              cursor: "pointer",
                            }}
                          >
                            <Image
                              id={`${parent_id}-${i}-trash-image`}
                              src="images/icons8-trash.svg"
                              alt="delete"
                              onClick={() => this.handleDelete(i)}
                            />
                          </Div>
                        )}
                        {names?.length > 1 && i === 0 && (
                          <Text
                            id={`${parent_id}-${i}-trash-showless-text`}
                            className="pc_medium"
                            style={{
                              color: "#F58B00",
                              // marginLeft: 15,
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => this.handleLimit()}
                          >
                            {this.state.limited
                              ? "Show Less"
                              : `Show +${names?.length}`}{" "}
                          </Text>
                        )}
                      </Div>
                    </Col>
                  </Row>
                ))}
              <Divider
                id={`${parent_id}-trash-divider`}
                className="p-dashed-divider "
                inLineStyles={{ margin: "5px 0px" }}
              />
              <Row
                id={`${parent_id}-parent-row`}
                className="pi-basic-details pi-actions-root">
                <Col
                  id={`${parent_id}-gender-col`}
                  className="no-padding-left" style={{ padding: "0" }}>
                  {/* <Select
                    isRequired={true}
                    showArrow
                    label="Gender"
                    getOptionLabel="label"
                    options={this?.props?.allMasters?.["gender"]}
                    value={details?.gender?.label ?? null}
                    onChangeValue={(e) => this.setDetails("gender", e)}
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={
                      details?.error?.gender ? errorBorderStyle : borderStyle
                    }
                    hoverColor="#0071F2"
                    // helperText={
                    //   details?.error && details?.error["gender"]
                    //     ? "Is required"
                    //     : ""
                    // }
                    error={
                      details?.error && details?.error["gender"] ? true : false
                    }
                  /> */}

                  {/* <Typography
                    style={{
                      fontSize: "12px",

                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Gender<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectBox
                    list={this?.props?.allMasters?.["gender"] ?? []}
                    placeholder={"select..."}
                    disableClearable
                    value={details?.gender}
                    onchange={(e, value) => this.setDetails("gender", value)}
                  /> */}

                  <SelectWithLabel
                    parent_id={'Gender'}
                    options={this?.props?.allMasters?.["gender"] ?? []}
                    label={"Gender"}
                    required={true}
                    disableClearable
                    value={details?.gender}
                    onChange={(value) => this.setDetails("gender", value)}
                    placeholder={"Select"}
                    // onChange={(data)=>props?.onChangeState('class',data)}
                    error={details?.error?.["gender"] ?? false}
                  />
                </Col>
                <Col
                  id={`${parent_id}-Date-of-Birth-col`}
                  className="no-padding-left" style={{ paddingRight: "0px" }}>
                  {/* <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                      paddingBottom: 8
                    }}
                    variant="body1"
                  >
                    Date of Birth <span style={{ color: "red" }}>*</span>
                  </Typography> */}
                  {/* <DateTimePicker
                    required={true}
                    label={""}
                    placeholder={"dd/mm/yyyy"}
                    dateFormat={"dd/MM/yyyy"}
                    value={details.dob ? details.dob : null}
                    onChange={(value) =>
                      this.setDetails("dob", moment(value)?.format("YYYY-MM-DD"), "age")
                    }
                    error={details?.error?.["dob"] ?? false}
                    maxDate={new Date()}
                    // minDate={details.dob ? moment(vdetails.dob).diff(moment(new Date()), 'days') >= 0 ? new Date() : moment(details.dob) : new Date()}
                  /> */}

                  {/* <TextInput
                    id={`${parent_id}-Date-of-Birth-textinput`}
                    value={details.dob ? details.dob : ""}
                    error={
                      details?.error && details?.error["dob"] ? true : false
                    }
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      this.setDetails("dob", e.target.value, "age")
                    }
                    isRequired={true}
                    label="Date of Birth"
                    type="date"
                    labelStyle={labelStyle}
                    style={details?.error?.dob ? errorBorderStyle : borderStyle}
                    hoverColor="#0071F2"
                  /> */}
                  <Typography
                    style={{
                      fontFamily: "poppin",
                      color: "#6F6F6F",
                      fontSize: "12px",
                      paddingBottom: "8px",
                    }}
                  >
                    {"Date of Birth"}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomInputDatePicker

                    disabled={false}
                    placeholderText="dd / mm / yyyy"
                    selectedDate={
                      details.dob ? new Date(details.dob) : null
                      // val?.expiry_date
                      //   ? new Date(utcTOLocal(val?.expiry_date, "MM/DD/YYYY"))
                      //  new Date()
                    }
                    maxDate={new Date()}
                    // minDate={(this?.props?.edit && this.props.oldData?.data?.identification?.[i]?.expiry_date) ? new Date(this.props.oldData?.data?.identification?.[i]?.expiry_date) : new Date()}
                    handleChange={(value) =>
                      this.setDetails("dob", value, "age")
                    }
                    inputField={
                      <TextInput
                        id={`${parent_id}-Date-of-Birth-textinput`}
                        fullWidth
                        placeholder="dd / mm / yyyy"
                        variant="outlined"
                        required={true}
                        disabled={false}
                        type={"text"}
                        value={
                          details.dob
                            ? moment(
                              new Date(
                                // utcTOLocal(val?.expiry_date, "MM/DD/YYYY")
                                details.dob
                              )
                            ).format("DD/MM/YYYY")
                            : null
                        }
                        error={this?.props?.error && !details.dob ? true : false}
                        inputProps={{
                          style: {
                            padding: "10.5px 14px",
                            fontFamily: "poppin",
                            fontSize: "12px",
                            borderRadius: 8,
                            borderColor: "#e0e0e0",
                            // backgroundColor: data?.disabled
                            //   ? "#E0E0E0"
                            //   : "#fff",
                          },
                        }}
                      />
                    }
                  />
                </Col>

                <Col
                  id={`${parent_id}-marital-status-col`}
                  className="no-padding-left" style={{ paddingRight: "0px" }}>
                  {/* <Select
                    // options={optiondata}
                    getOptionLabel="label"
                    options={this?.props?.allMasters?.["marital_status"]}
                    label="Marital status"
                    value={details?.marital_status?.label ?? null}
                    onChangeValue={(e) => this.setDetails("marital_status", e)}
                    showArrow
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={borderStyle}
                    hoverColor="#0071F2"
                  /> */}

                  {/* <Typography
                    style={{
                      fontSize: "12px",

                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Marital status <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectBox
                    list={this?.props?.allMasters?.["marital_status"] ?? []}
                    placeholder={"select..."}
                    disableClearable
                    value={details?.marital_status}
                    onchange={(e, value) =>
                      this.setDetails("marital_status", value)
                    }
                  /> */}


                  <SelectWithLabel
                    parent_id={'marital-status'}
                    label={"Marital status"}
                    options={this?.props?.allMasters?.["marital_status"] ?? []}
                    // required={true}
                    disableClearable
                    value={details?.marital_status}
                    onChange={(value) => this.setDetails("marital_status", value)}
                    placeholder={"Select"}
                  // onChange={(data)=>props?.onChangeState('class',data)}
                  // error={details?.error?.["gender"]??false}
                  />

                </Col>

                <Col
                  id={`${parent_id}-nationality-col`}
                  className="no-padding-left">
                  {/* <Typography
                    style={{
                      fontSize: "12px",

                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Nationality <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectBox
                    list={this?.props?.allMasters?.["nationality"] ?? []}
                    placeholder={"select..."}
                    disableClearable
                    value={details?.nationality}
                    onchange={(e, value) =>
                      this.setDetails("nationality", value)
                    }
                  /> */}

                  <SelectWithLabel
                    parent_id={'nationality'}
                    label={"Nationality"}
                    options={this?.props?.allMasters?.["nationality"] ?? []}
                    required={true}
                    disableClearable
                    value={details?.nationality}
                    onChange={(value) => this.setDetails("nationality", value)}
                    placeholder={"Select"}
                    error={details?.error?.["nationality"] ?? false}
                  />

                </Col>

                {/* <Col className="no-padding-left" style={{ padding: "0" }}>
                  <H6 className="default-size pc_regular">External</H6>
                  <Checkbox
                    checked={details?.external}
                    onChange={(e) =>
                      this.setDetails("external", !details?.external)
                    }
                    name=""
                    color="#0071F2"
                  />
                </Col> */}
                {/* <Col className="no-padding-left" style={{ padding: "0" }}>
                <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                      paddingBottom: 8
                    }}
                    variant="body1"
                  >
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                <TextInput
                  value={details?.email ? details?.email : ""}
                  onChange={(e) =>
                    // this.setData("email", e.target.value)
                    this.setDetails("email", e.target.value)
                  }
                  isRequired={true}
                  error={
                    details?.error && details?.error["email"] ? true : false
                  }
                  placeholder="email"
                  // label="email"
                  labelStyle={labelStyle}
                  style={
                    details?.error?.middle_name
                      ? errorBorderStyle
                      : borderStyle
                  }
                  hoverColor="#0071F2"
                />
                </Col> */}
                {this.props?.[this.props.saveto]?.pract_id && (
                <Col
                  id={`${parent_id}-Status-col`}
                  className="no-padding-left" >
                  <H6
                    id={`${parent_id}-Status-title-h6`}
                    className="default-size pc_regular" style={{ paddingBottom: 8 }} >Status</H6>
                  <FormControlLabel
                    id={`${parent_id}-Status-formcontrolLabel`}
                    control={
                      <IOSSwitch
                        id={`${parent_id}-Status-IOSSwitch`}
                        checked={details?.status}
                        onChange={(e) =>
                          this.setDetails("status", !details?.status)
                        }
                      />
                    }
                  />
                </Col>
                )}
              </Row>
            </Paper>
          </Col>
        </Row>
      </Div>
    );
  }
}

export default withAllContexts(PatientNames);
