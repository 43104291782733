import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import availabilitylogo from "../../../assets/icons8-management (5).svg";
import Practitioner_Master from "../../../assets/Practitioner_Master.svg";
import userLogo from "../../../assets/icons8-user-folder (1).svg";
import dashBoardLogo from "../../../assets/icons8-dashboard-layout (7).svg";
import orgSetupLogo from "../../../assets/icons8-laptop-settings.svg";
import orgLogo from "../../../assets/icons8-business-building.svg";
import engineering from "../../../assets/engineering.png";
import businessLogo from "../../../assets/icons8-database-administrator (1).svg";
import { Image, Div, H6 } from "qdm-component-library";
import { routes } from "../../../router/routes";
import { useHistory } from "react-router";
import Config from "../../../config";
import { getPermissionRoutes } from "../../../utils";
import Assign from '../../../assets/erefasignlogo.png'
import AtpManagement from '../../../assets/atpmail.svg'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: props?.drawerWidth ?? 80,
    height: "100vh",
    position: "fixed",
    zIndex: 100,
  }),
  drawer: (props) => ({
    width: props?.drawerWidth ?? 80,
    // width: 307,
    height: "inherit",
    // height: "56px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    // boxShadow: "15px 2px 17px #1010100D",
    border: "1px solid #DADADA",
    transition: "width 80ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    overflow: "scroll",
    zIndex: 9999,
    "& .h6": {
      display: "none",
      fontSize: "14px !important",
      margin: "0 0 0 20px !important",
      whiteSpace: "nowrap",
    },
    "&:hover": {
      overflow: "scroll !important",
      width: 307,
      boxShadow: "11px 0 11px -2px #dedddd",
      position: "absolute",
      zIndex: 100,
      "& .h6": {
        display: "flex",
      },
    },
  }),
  rowCenter: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    padding: "5px 30px 0px 30px",
    cursor: "pointer",
  },
  images: {
    height: "18px !important",
    width: "18px !important",
  },
}));

let json = {
  admindashboard: {
    id: "1",
    label: "Dashboard",
    logo: dashBoardLogo,
  },
  OrganizationConfig: {
    id: "10",
    label: "Organization Config",
    link: routes.organizationConfig,
    logo: orgLogo,
    subList: [
      {
        id: 0,
        listName: "Care Type",
      },
      {
        id: 1,
        listName: "Level of Care",
      },
      {
        id: 2,
        listName: "Health Care Service",
      },
    ],
  },
  organizations: {
    id: "2",
    label: "Organizations",
    link: routes.organization,
    logo: orgLogo,
    subList: [
      {
        id: 0,
        listName: "Enterprise Details",
      },
      {
        id: 1,
        listName: "External",
      },
    ],
  },
  // "organizationSetup": {
  //   id: "3",
  //   label: "Organization Setup",
  //   logo: orgSetupLogo,
  // },
  parameterSetup: {
    id: "3",
    label: "Parameter Setup",
    link: routes.parameterSetup,
    logo: orgLogo,
    subList: [
      {
        id: 0,
        listName: "Admin",
      },
      {
        id: 1,
        listName: "Patient",
      },
      {
        id: 2,
        listName: "Appointment",
      },
      {
        id: 3,
        listName: "Others",
      },
    ],
  },
  administrations: {
    id: "4",
    label: "Administration",
    link: routes.administration,
    logo: businessLogo,
    subList: [
      {
        id: 0,
        listName: "General Master",
      },
      {
        id: 1,
        listName: "Geographic Master",
      },
      {
        id: 2,
        listName: "Location",
      },
      {
        id: 3,
        listName: "Pending Reschedule List",
      },
      {
        id: 4,
        listName: "Visit Type (GM)",
      },
      {
        id: 5,
        listName: "Symptoms & specialities",
      },
      {
        id: 6,
        listName: "Patient Importance type (GM)",
      },
      {
        id: 7,
        listName: "Location Role Type (GM)",
      },
      {
        id: 8,
        listName: "Encounter Type",
      },
    ],
  },
  availabilityManagement: {
    id: "5",
    label: "Availability Management",
    link: routes.availabilitymanagement,
    logo: availabilitylogo,
    subList: [
      {
        id: 0,
        listName: "Holiday",
      },
      {
        id: 1,
        listName: "Time Table",
      },
      {
        id: 2,
        listName: "Resource by appointment type",
      },
      {
        id: 3,
        listName: "Appointment type",
      },
      {
        id: 4,
        listName: "Schedule management",
      },
    ],
  },
  userManagement: {
    id: "6",
    label: "User Management",
    link: routes.repository,
    logo: userLogo,
    // subList: [
    //   {
    //     id: 0,
    //     listName: "Repository",
    //   },
    //   {
    //     id: 1,
    //     listName: "Permission List",
    //   },
    //   {
    //     id: 2,
    //     listName: "Roles",
    //   },
    //   {
    //     id: 3,
    //     listName: "Person",
    //   },
    //   {
    //     id: 4,
    //     listName: "Users",
    //   },
    // ],
  },
  practitionerMaster: {
    id: "7",
    label: "Practitioner Master",
    link: routes.practitionermaster,
    logo: Practitioner_Master,
    subList: [
      {
        id: 0,
        listName: "Practitioner",
      },
      {
        id: 1,
        listName: "Practitioner Role",
      },
      {
        id: 2,
        listName: "Practitioner Type",
      },
      {
        id: 3,
        listName: "Qualification",
      },
      {
        id: 4,
        listName: "Position & Grades (GM)",
      },
    ],
  },
  consultMaster: {
    id: "8",
    label: "Consult Masters",
    link: routes.consult,
    logo: userLogo,
    subList: [
      {
        id: 0,
        listName: "Age Ranges",
      },
      {
        id: 1,
        listName: "Vitals",
      },
      {
        id: 2,
        listName: "Order",
      },
      {
        id: 3,
        listName: "Drug",
      },
      {
        id: 4,
        listName: "Order Set",
      },
      {
        id: 5,
        listName: "Allergen Master",
      },
      {
        id: 6,
        listName: "Diagnosis Master",
      },
      {
        id: 7,
        listName: "Flex Attribute",
      },
      {
        id: 8,
        listName: "Flex Attribute Applicable Criteria",
      },
    ],
  },
  FrequencyMaster: {
    id: "11",
    label: "Frequency Master",
    link: routes.frequency,
    logo: orgLogo,
  },
  DrugMasters: {
    id: "11",
    label: "Drug Master",
    link: routes.DrugMasters,
    logo: orgLogo,
  },
  ruleBuilder: {
    id: "9",
    label: "Rule Builder",
    link: "",
    // redirectUrl: `${Config.ruleBuilderUiUrl}/rules?token=68b4e72f-d774-42d1-927c-4ac81d2adedc`,
    logo: engineering,
    subList: [
      {
        id: 0,
        listName: "Facts Definition",
        link:routes.factsDefinition
      },
    ],
  },
  ereferral: {
    id: "10",
    label: "E-Referral",
    link: "",
    logo: Assign,
    subList: [
      {
        id: 0,
        listName: "Referral Rights Defintion",
        link:routes.referalrights
      },
      {
        id: 1,
        listName: "Referral Types",
        link:routes.referraltype
      },
    ],
  },
  // AlertNotification: {
  //   id: "12",
  //   label: "AlertNotification",
  //   link: routes.alertnotification,
  //   logo: orgLogo,
  // },
  atpmanagement: {
    id: "10",
    label: "ATP Management",
    link: "",
    logo: AtpManagement,
    subList: [
      {
        id: 0,
        listName: "API Configuration",
        link:routes.apiconfiguration
      },
      {
        id: 1,
        listName: "Event Configuration",
        link:routes.eventconfiguration
      },
      {
        id: 2,
        listName: "Alert Notification",
        link:routes.alertnotification
      },
    ],
  },
};

let dataList = [
  "admindashboard",
  "OrganizationConfig",
  "organizations",
  "parameterSetup",
  "administrations",
  "availabilityManagement",
  "userManagement",
  "practitionerMaster",
  "consultMaster",
  "FrequencyMaster",
  "DrugMasters",
  "ruleBuilder",
  "ereferral",
  // "AlertNotification",
  "atpmanagement",
];

export const HoverNavBar = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  const [drawerList, setDrawerList] = React.useState([]);

  const [isHovered, setIsHovered] = React.useState(false);
  const [clickedList, setClickedList] = React.useState("");

  const onMouseEnterHandler = () => {
    setIsHovered(true);
  };
  const onMouseLeaveHandler = () => {
    setIsHovered(false);
  };

  const [selectedIndex, setSelectedIndex] = React.useState("");

  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(index);
    }
  };

  React.useEffect(() => {
    let arr = getPermissionRoutes();
    let list = [];
    dataList.map((val) => {
      if (arr.indexOf(val) > -1) {
        list.push(json[val]);
      }
    });
    setDrawerList(list);
  }, []);

  const handleLink = (_, item = {}) => {
    if (_?.link) {
      history.push({
        pathname: _?.link,
        state: { index: item?.id },
      });
    }
    if (_?.redirectUrl) {
      window.open(_?.redirectUrl);
    }
  };

  const onlistLink = (_, item) => {
    handleLink(_, item);
    setClickedList(item?.listName);
    if (item?.link) {
      history.push({
        pathname: item?.link,
        state: { index: item?.id },
      });
    }
  };

  const activeColor = (val) => {
    return history.location.pathname === val?.link ? "#0071F2" : "#6F6F6F";
  };
  const activeListColor = (val) => {
    return clickedList === val ? "#0071F2" : "#6F6F6F";
  };

  return (
    <div id={"hovernavbar-parent-div"} className={classes.root}>
      <div
        id={"hovernavbar-parent-sub-div"}
        className={classes.drawer}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        {drawerList?.map((_, i) => {
          return (
            <>
              <ListItem
                button
                onClick={() => handleClick(i)}
                className={classes.rowCenter}
                key={i}
              >
                <ListItemIcon>
                  {
                    <Image
                      id={"hovernavbar-logo-image" + i}
                      src={_?.logo}
                      alt="logo"
                      className={classes.images}
                    />
                  }
                </ListItemIcon>
                {isHovered ? (
                  <ListItemText
                    primary={_?.label}
                    onClick={() => handleLink((_ = _))}
                    primaryTypographyProps={{ fontSize: "14px !important" }}
                    style={{ color: activeColor(_) }}
                    disableTypography
                  />
                ) : (
                  <></>
                )}
                {i === selectedIndex && isHovered
                  ? _?.subList?.length > 0 && (
                      <ExpandLess style={{ marginRight: "-14px" }} />
                    )
                  : _?.subList?.length > 0 && (
                      <ExpandMore style={{ marginRight: "-14px" }} />
                    )}
              </ListItem>
              {isHovered ? (
                <Collapse
                  in={i === selectedIndex}
                  timeout="auto"
                  unmountOnExit
                  collapsedSize="auto"
                >
                  {_?.subList?.map((item, index) => {
                    return (
                      <>
                        <List component="div" disablePadding key={index}>
                          <ListItem
                            button
                            className={classes.nested}
                            onClick={() => onlistLink(_, item)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText
                              primary={item?.listName}
                              primaryTypographyProps={{
                                fontSize: "12px !important",
                              }}
                              style={{ color: activeListColor(item?.listName) }}
                              disableTypography
                            />
                          </ListItem>
                        </List>
                      </>
                    );
                  })}
                </Collapse>
              ) : (
                <></>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
